@font-face {
    font-family: "MuseoSans";
    src: url("./fonts/museo_sans_ssv/MuseoSans-100.woff2") format("woff2"),
        url("./fonts/museo_sans_ssv/MuseoSans-100.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MuseoSans";
    src: url("./fonts/museo_sans_ssv/MuseoSans-300.woff2") format("woff2"),
        url("./fonts/museo_sans_ssv/MuseoSans-300.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MuseoSans";
    src: url("./fonts/museo_sans_ssv/MuseoSans-500.woff2") format("woff2"),
        url("./fonts/museo_sans_ssv/MuseoSans-500.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MuseoSans";
    src: url("./fonts/museo_sans_ssv/MuseoSans-700.woff2") format("woff2"),
        url("./fonts/museo_sans_ssv/MuseoSans-700.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
